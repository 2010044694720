@import url('https://fonts.googleapis.com/css2?family=Poor+Story&family=Ubuntu:wght@300&display=swap');

html {
  background-color: #afeae3;
  font-size: 18px;
  
}

body, span {
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  color: #006086;
  margin: 5px;
  text-align: left;
}

p {
  text-align: justify;
  width: 70%;
  margin-left: 10px;
  line-height: 25px;
}

a {
  /* margin-left: 20px; */
  /* margin-left: 20px; */
}

span div {
  margin-left: 3px;
  line-height: 23px;
}

.intro {
  text-align: left;
}

/* Home button */
.homeBtn {
  background-color: #708e8a;
  
  position: fixed;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
}

.homeBtn svg {
  color: white;
  width: 48px;
  height: 48px;
}

.home {
  margin-left: 0px;
}
