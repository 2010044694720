#footer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	width: 100%;
  background-color: #006086;
  margin-top: auto;
}

	#footerCopyright {
		margin-left: 10px;
		font-weight: bold;
		font-size: 20px;
    color: white;
	}
