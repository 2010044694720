.menuGridTotaalBeeld {
	max-width: 900px !important;
	margin: 15px !important;
	grid-gap: 10px;
}


.totaalBeeldItem { 
	color: white;
	font-weight: bold;
	background-color: rgb(43, 168, 226);
	padding: 15px;
	margin-right: 10px;
	border-radius: 10px;
	height: 95px;
	justify-content: center;
	align-items: center;
}

.totaalBeeldItem:hover { 
	cursor: pointer;	
	background-color: rgb(9, 56, 78);
}

.noUnderline {
	text-decoration: none;
	color: white;
	display:flex;
}