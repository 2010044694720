.menuGrid {
	max-width: 900px !important;
	margin: 15px !important;
}

.menuItem {
	/* margin: 0px 0px 10px 10px; */
	/* GREY TO COLOUR */
	filter: gray; 
	-webkit-filter: grayscale(1); 
	-webkit-transition: all .8s ease-in-out;
}

.menuItem:hover {
	/* GREY TO COLOUR */
	filter: none;
	-webkit-filter: grayscale(0);
	-webkit-transform: scale(1.01);
	/* TRANSPARANCY */
}
